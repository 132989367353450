import React from "react";

function Content() {
  return (
    <section className="client-section">
      <div className="container">
        <div className="client-slider section-gap line-top">
          <DelegatesStateCentralCommittee />
          <br />
          <DelegatesCongressionalDistrictThree />
          <br />
          <DelegatesCongressionalDistrictFive />
          <br />
          <DelegatesCongressionalDistrictSix />
        </div>
      </div>
    </section>
  );
}

function DelegatesCongressionalDistrictSix() {
  const delegates = ["Roger Fontes"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 6
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesCongressionalDistrictFive() {
  const delegates = ["Laurie Kilbourn"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 5
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesCongressionalDistrictThree() {
  const delegates = ["Nick Walden Poublon"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates to Congressional District 3
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

function DelegatesStateCentralCommittee() {
  const delegates = ["SCC Chair Diane Grover"];
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          <i className="fa fa-users" /> Delegates State Central Committee
        </h5>
        <p className="card-text p-5">
          <ul>
            {delegates.map((delegate) => (
              <li key={delegate}>{delegate}</li>
            ))}
          </ul>
        </p>
      </div>
    </div>
  );
}

export default Content;
