import React, { Component, Fragment } from "react";
import About from "./comps/About";
import Banner from "./comps/Banner";
import Service from "./comps/Service";
import Video from "./comps/Video";
import Whyus from "./comps/Whyus";
import Features from "./comps/Features";
import Merch from "./comps/Merch"

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        {/* <UpcomingEvents /> */}
        <About />
        <Video />
        <Merch />
        <Service />
        <Whyus />
        <Features />
      </Fragment>
    );
  }
}

export default Content;
