import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Blogstandard from "./components/pages/Blogstandard";
import Blogdetail from "./components/pages/Blogdetail";
import ContactsAndOrganization from "./components/pages/ContactsAndOrganization";
import EventsCalendar from "./components/pages/EventsCalendar";
import PCP from "./components/pages/PCP";
import OurMission from "./components/pages/OurMission";
import Officers from "./components/pages/Officers";
import HouseDistrictLeaders from "./components/pages/HouseDistrictLeaders";
import StandingCommittee from "./components/pages/StandingCommittee";
import DpccByLaw from "./components/pages/DpccByLaw";
import Documentation from "./components/pages/Documentation";
import CommitteeMeetings from "./components/pages/CommitteeMeetings";
import StandingRules from "./components/pages/StandingRules";
import ParliamentaryProcedure from "./components/pages/ParliamentaryProcedure";
import OtherWaysToDonate from "./components/pages/OtherWaysToDonate";
import NeighborhoodLeaders from "./components/pages/NeighborhoodLeaders";
import Delegates from "./components/pages/Delegates";
import Platform from "./components/pages/Platform";
import GettingReadyToRun from "./components/pages/CandidatePortal/GettingReadyToRun";
import ShouldYouRun from "./components/pages/CandidatePortal/ShouldYouRun";
import CandidateCommunicationsStrategy from "./components/pages/CandidatePortal/CandidateCommunicationsStrategy";
import CandidateTraining from "./components/pages/CandidatePortal/CandidateTraining";
import CandidateResources from "./components/pages/CandidatePortal/CandidateResources";
import Donate from "./components/pages/Donate";
import OregonTrailDemocrats from "./components/pages/OregonTrailDemocrats";
import Volunteer from "./components/pages/Volunteer";

export default () => {
  // const location = useLocation();
  // const getFormattedPageTitle = (pageTitle) => {
  //   // format "contacts-and-organization" to "Contacts and Organization"
  //   return pageTitle
  //     .split("-")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // }

  // useEffect(() => {
  //   const url = `https://clackamasdems.org/${location.hash}${location.search}`;
  //   const dataLayer = {
  //     event: "virtualPageView",
  //     pageUrl: url,
  //     pageTitle: getFormattedPageTitle(location.hash.split("/").pop()),
  //   };
  //   // window.dataLayer.push(dataLayer);
  // }, [location]);

  return (
    <Router>
      <Preloader />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path="/contacts-and-organization"
          component={ContactsAndOrganization}
        />
        <Route path="/about-us" component={About} />
        <Route path="/blog-posts" exact component={Blogstandard} />
        <Route path="/news" exact component={Blogstandard} />
        <Route path="/blog-posts/:title" component={Blogdetail} />
        <Route path="/standing-rules" component={StandingRules} />
        <Route
          path="/parliamentary-procedure"
          component={ParliamentaryProcedure}
        />
        <Route path="/other-ways-to-donate" component={OtherWaysToDonate} />
        <Route
          path="/neighborhood-leaders-program"
          component={NeighborhoodLeaders}
        />
        <Route path="/delegates" component={Delegates} />
        <Route
          path="/oregon-trail-democrats"
          component={OregonTrailDemocrats}
        />
        <Route path="/events-calendar" component={EventsCalendar} />
        <Route path="/pcp" component={PCP} />
        <Route path="/our-mission" component={OurMission} />
        <Route path="/officers" component={Officers} />
        <Route
          path="/house-district-leaders"
          component={HouseDistrictLeaders}
        />
        <Route
          path="/standing-committee-and-caucuses"
          component={StandingCommittee}
        />
        <Route path="/dpcc-by-laws" component={DpccByLaw} />
        <Route path="/documentation" component={Documentation} />
        <Route path="/platform" component={Platform} />
        <Route path="/donate" component={Donate} />
        <Route
          path="/join-our-central-committee-meetings"
          component={CommitteeMeetings}
        />
        <Route path="/volunteer-your-time" component={Volunteer} />
        <Route
          path="/candidate-portal/getting-ready-to-run"
          component={GettingReadyToRun}
        />
        <Route
          path="/candidate-portal/should-you-run"
          component={ShouldYouRun}
        />
        <Route
          path="/candidate-portal/candidate-communications-strategy"
          component={CandidateCommunicationsStrategy}
        />
        <Route
          path="/candidate-portal/candidate-training"
          component={CandidateTraining}
        />
        <Route
          path="/candidate-portal/candidate-resources"
          component={CandidateResources}
        />
      </Switch>
    </Router>
  );
};
