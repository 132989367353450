import React from "react";

function Content() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "36px",
      }}
    >
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>PCP Application</h3>
                </div>
                <div className="card-body p-3">
                  <p style={{ marginBottom: "14px" }}>
                    PCPs are neighborhood representatives and the core of the
                    Democratic Party. PCPs promote Democratic Party causes and
                    values, help elect Democratic candidates, and vote on policy
                    issues and official Party business. Only PCPs may vote at
                    Central Committee meetings.
                  </p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfd9UxADWE5xGxReb_48nSzbx8p0KFOYWQMVz3sLEFDrLSfgA/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply online to become a PCP member.
                  </a>
                </div>
              </div>
              <div className="card-body p-3"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3>PCP Handbook</h3>
                </div>
                <div className="card-body p-3">
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "0",
                    }}
                  >
                    The PDF is both far easier to read, includes all the images
                    (some not recreated for this format) and IS THE OFFICIAL
                    DOCUMENT– you can get the PDF here:{" "}
                    <a
                      href="https://drive.google.com/file/d/1TtniXT5-iGesKyiVnwJjqo1ywUl16sfV/view"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      [Download the PDF here]
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content;
