import React from "react";
import { Link } from "react-router-dom";

import illustration from "../../../../assets/img/illustration/pexels-pixabay-326576.jpg";
import line1 from "../../../../assets/img/lines/03.png";
import line2 from "../../../../assets/img/lines/04.png";

function Whyus(props) {
  return (
    <section className="wcu-section box-style">
      <div className="container">
        <div className="wcu-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6">
              <div
                className="wcu-image text-center text-lg-left wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="400ms"
              >
                <img src={illustration} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="wcu-text">
                <div className="section-title left-border mb-40">
                  <span className="title-tag text-light">Endorsement</span>
                  <h2 className="title">
                    Get Endorsed by <br />
                    the Democratic Party of Clackamas County
                  </h2>
                </div>

                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                  className="main-btn main-btn-4"
                >
                  Details
                </a>
              </div>
            </div>
          </div>
          <img src={line1} alt="shape" className="line-shape-one" />
          <img src={line2} alt="shape" className="line-shape-two" />
        </div>
      </div>
    </section>
  );
}

export default Whyus;
