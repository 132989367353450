import React, { Component } from "react";
import { Link } from "react-router-dom";

import breadcrumbimg from "../../assets/img/banner/dpcc-2.png";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";

const styles = css`
    padding: 2em;
    .container {
      .breadcrumb-text {
        .page-title {
          font-size: 3em;
        }
        
        ul {
          li {
            font-size: 1.5em;
            margin: 0 20px;
            a {
              padding: 10px
            }
          }
        }
      }
    }
  }
`;

function Breadcrumbs({ breadcrumb }) {
  return (
    <section
      css={styles}
      className="breadcrumb-section bg-img-c"
      style={{ backgroundImage: "url(" + breadcrumbimg + ")" }}
    >
      <div className="container">
        <div className="breadcrumb-text">
          <h1 className="page-title">{breadcrumb.pagename}</h1>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>{breadcrumb.pagename}</li>
          </ul>
        </div>
      </div>
      <div className="breadcrumb-shapes">
        <div className="one" />
        <div className="two" />
        <div className="three" />
      </div>
    </section>
  );
}

export default Breadcrumbs;
