import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Footer from "../layouts/Footer";

import bylawsPDF from "../../assets/files/bylaws.pdf";
import climate_change_resolution from "../../assets/files/Resolution-2022-001-Climate-Change.pdf";
import abortion_rights_resolution from "../../assets/files/Resolution-2022-002-Protecting-A-Womans-Right-to-Abortion.pdf";
import { useLocation } from "react-router-dom";

function DpccByLaw() {
  const location = useLocation();

  const pageTitle = "Resolutions and Bylaws | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County resolutions and bylaws. Get to know us by reading the DPCC bylaws and important resolutions."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "DPCC Bylaws" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p>
                    The Central Committee is a governance body whose members set
                    policy, endorse candidates, and make resource allocation
                    decisions. The committee meets monthly and is made up of
                    leadership and participant roles as outlined in the bylaws
                    (below), including all Precinct Committee Persons (PCPs).
                    The Central Committee serves as a place to hear the concerns
                    of Democrats in the county and develop strategies to get our
                    candidates elected. The Central Committee seeks a diverse
                    and inclusive membership representing a broad demographic
                    spectrum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-center">Download DPCC Resolutions</h4>
                </div>
                <div className="card-body p-3">
                  <ul>
                    <li>
                      <a href={climate_change_resolution} download>
                        Download "Resolution 2022-001 Climate Change"
                      </a>
                    </li>
                    <li>
                      <a href={abortion_rights_resolution} download>
                        Download "Resolution 2022-002 Protecting A Woman's Right
                        to Abortion"
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-center">Download DPCC Bylaws</h4>
                </div>
                <div className="card-body p-3">
                  <a href={bylawsPDF} download>
                    Download PDF file
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0">
            <div className="col pt-3">
              <div className="card">
                <div className="card-header">
                  <h3 className="text-center">
                    Democratic Party of Clackamas County
                  </h3>
                  <h4 className="text-center">
                    BYLAWS OF THE CENTRAL COMMITTEE (Revised 4/21/2023)
                  </h4>
                </div>
                <div className="card-body p-3">
                  <p>ARTICLE I NAME</p>
                  <blockquote>
                    <p>
                      This organization shall be called the Central Committee of
                      the Democratic Party of Clackamas County.
                    </p>
                  </blockquote>
                  <p>ARTICLE II STRUCTURE AND AUTHORITY</p>
                  <blockquote>
                    <p>
                      A. The Democratic Party of Clackamas County (DPCC)
                      consists of all Democrats registered to vote in Clackamas
                      County.
                    </p>
                    <p>
                      B. The governing body of the Democratic Party of Clackamas
                      County is the Clackamas County Central Committee,
                      consisting of Precinct Committee Persons (PCPs) and others
                      as specified elsewhere in these Bylaws. PCPs are elected
                      by Democratic voters at the primary election or, when
                      vacancies occur, appointed by the Central Committee. [ORS
                      248.015, 248.031]
                    </p>
                    <p>
                      C. The Central Committee is organized under the authority
                      of Chapter 248 of the Oregon Revised Statutes and the
                      Bylaws of the Democratic Party of Oregon and is the
                      highest authority in county party matters in Clackamas
                      County. It conducts its business in compliance with
                    </p>
                    <p>1. Oregon Revised Statutes</p>
                    <p>
                      2. Bylaws and Standing Rules of the Democratic Party of
                      Oregon
                    </p>
                    <p>
                      3. Bylaws and Standing Rules of the Clackamas County
                      Democratic Party Central Committee
                    </p>
                    <p>
                      4. Relevant provisions of the most recent version of
                      <em>Robert’s Rules of Order</em> in procedural matters not
                      otherwise specified
                    </p>
                  </blockquote>
                  <p>ARTICLE III PURPOSE</p>
                  <blockquote>
                    <p>
                      A. The Central Committee of the Democratic Party of
                      Clackamas County is responsible to
                    </p>
                    <p>
                      1. Carry out the goals of the national, state and county
                      Democratic Party platforms
                    </p>
                    <p>2. Strive to have the party goals enacted into law</p>
                    <p>
                      3. Work to develop and maintain Democratic Party unity
                    </p>
                    <p>
                      4. Help elect Democratic candidates to partisan and
                      non-partisan positions
                    </p>
                    <p>
                      5. Inform Clackamas County voters about the principles,
                      issues, and candidates supported by the Democratic Party
                    </p>
                    <p>
                      B. The Central Committee of the DPCC shall conduct its
                      activities in a fair and open manner so that all
                      interested members of the Democratic Party of Clackamas
                      County are encouraged to participate in party activities.
                    </p>
                  </blockquote>
                  <p>ARTICLE IV MEMBERSHIP</p>
                  <blockquote>
                    <p>
                      A. Membership in the Central Committee of the Democratic
                      Party of Clackamas County consists of all elected and
                      appointed precinct committee persons (PCPs) in Clackamas
                      County, officers of the DPCC, and members of the Executive
                      Committee of the DPCC Central Committee.
                    </p>
                    <p>
                      B. In addition, any registered Democrat who holds elective
                      public office within Clackamas County may become a member
                      of the Central Committee by submission of a request to the
                      chair and subsequent approval of the Executive Committee
                      and Central Committee.
                    </p>
                    <p>
                      C. Precinct committee persons are representatives of the
                      Democratic Party in the precinct (ORS 248.015). Elected
                      PCPs begin their terms upon formal notification of their
                      election by the County Clerk following a primary election
                      and serve until the next primary election unless they
                      resign or are recalled. PCPs may resign by written
                      notification to the county clerk (ORS 248.024) and may be
                      recalled by the voters of the precinct they represent
                      according to procedures described in ORS 248.029. (See
                      Art. IX.A.)
                    </p>
                    <p>
                      D. Appointed PCPs serve from the time their appointment is
                      recorded by the County Clerk until the next primary
                      election unless they are removed by the Central Committee.
                      (ORS 248.025.3) They have the same powers and
                      responsibilities as elected PCPs except that they may not
                      vote in the organizational election of county officers.
                      They may vote in the election of delegates and alternates
                      listed in Article V.C., as well as for House District
                      Leaders. Appointed PCPs may also vote in elections to fill
                      officer vacancies and all other business before the
                      Central Committee<em>.</em> (ORS 248.026.4)
                    </p>
                    <p>
                      E. PCPs continue to represent the precinct to which they
                      are elected or appointed and retain their membership in
                      the Central Committee until the end of their term, even if
                      precinct boundaries change during that time (ORS 248.027).
                    </p>
                  </blockquote>
                  <p>ARTICLE V ORGANIZATION</p>
                  <blockquote>
                    <p>
                      A. The Central Committee shall conduct the business of the
                      DPCC at monthly meetings (except for December and August)
                      open to the public except when meeting in executive
                      session. Members of the Central Committee have a right to
                      vote on all issues unless otherwise specified in these
                      Bylaws.
                    </p>
                    <p>
                      B. The Central Committee shall be assisted in its
                      management of the DPCC by the following:
                    </p>
                    <p>
                      1. Officers of the Central Committee, namely a Chair,
                      First Vice-Chair, Second Vice-Chair, Recording Secretary,
                      Electronic Communications Secretary, and Treasurer elected
                      at the biennial organizational meeting or as vacancies
                      occur
                    </p>
                    <p>
                      2. House district leaders (HDLs), PCPs who are elected by
                      the PCPs in each House District at the biennial
                      organizational meeting or as vacancies occur
                    </p>
                    <p>
                      3. An Executive Committee consisting of Central Committee
                      officers, house district leaders, chairs of both the SCC
                      and Congressional delegations, chairs of standing
                      committees, and chairs of caucuses
                    </p>
                    <p>
                      4. Standing committees appointed by the Central Committee
                      Chair with the advice and consent of the Executive
                      Committee
                    </p>
                    <p>
                      5. Special committees appointed by the Central Committee
                      Chair
                    </p>
                    <p>
                      6. Special interest caucuses comprised of Clackamas County
                      Democrats supporting common interests, views, and/or
                      identities within the Democratic Party
                    </p>
                    <p>
                      C. The Central Committee sends representatives to other
                      Democratic Party of Oregon entities with the number of
                      representatives determined by the rules of the DPO,
                      including
                    </p>
                    <p>
                      1. Delegates and alternates to the State Central
                      Committee—elected at the biennial organizational meeting
                      or as vacancies occur
                    </p>
                    <p>
                      2. Delegates and alternates to each congressional district
                      located within the county—elected at the biennial
                      organizational meeting or as vacancies occur
                    </p>
                    <p>
                      3. Delegates and alternates to the biennial state platform
                      convention—elected by the Central Committee
                    </p>
                  </blockquote>
                  <p>ARTICLE VI MEETINGS</p>
                  <blockquote>
                    <p>
                      A. Meetings of the Central Committee are generally open to
                      the public; however, any portion of any meeting may be
                      conducted in private or executive session with only
                      members present when decisions affecting the internal
                      workings of the Democratic Party are being discussed.
                    </p>
                    <p>
                      1. Notice of the time and place of regular monthly
                      meetings of the Central Committee shall be posted on the
                      website; in addition, all members of the Central Committee
                      shall be notified by mail or email of record sent no later
                      than the sixth day before the meeting. By statute (ORS
                      248.012), actions taken at a meeting held without due
                      notice are invalid.
                    </p>
                    <p>
                      2. Business of the Central Committee is normally discussed
                      in advance by the Executive Committee, which may refer it
                      to an appropriate standing committee for further
                      consideration or seek further information before passing
                      it on to the Central Committee, with or without a
                      recommendation.
                    </p>
                    <p>
                      3. The Chair prepares the agenda of meetings of the
                      Central Committee. Requests to place items on the agenda
                      must be received by the Chair at least 10 days before a
                      meeting and may be referred to the Executive Committee or
                      a standing or special committee prior to inclusion on the
                      Central Committee agenda.
                    </p>
                    <p>
                      4. The circulated agenda for a Central Committee meeting
                      may be amended on a debatable motion by two-thirds of
                      those present and voting or by unanimous consent.
                    </p>
                    <p>
                      5. Resolutions that express a position of the Central
                      Committee on a public issue (as distinct from substantive
                      motions or resolutions relating to the operations of the
                      DPCC) and not already addressed in actions of the DPO
                      shall be in order if they comply with guidelines provided
                      in Standing Rule 7 (on submitting resolutions). Such
                      resolutions shall be included in the agenda of the next
                      Central Committee meeting
                    </p>
                    <p>
                      a. if they are submitted to the Chair at least ten days
                      before the meeting, or
                    </p>
                    <p>
                      b. if the substance of the intended motion has been
                      announced to the previous Central Committee meeting. Such
                      announcements shall be included in the minutes of the
                      meeting in which they are presented. In addition, the full
                      text of the resolution shall be published in the notice of
                      the meeting at which it will be considered.
                    </p>
                    <p>
                      c. Resolutions subject to this section that comply with
                      the related standing rule but do not comply with items a.
                      or b. of this section may nonetheless be taken up and
                      considered on a debatable motion to do so. Approval of a
                      resolution taken up in this manner shall require a
                      two-thirds affirmative vote.
                    </p>
                    <p>
                      d. If a resolution is considered under VI.A. 5.c and fails
                      to pass with a two-thirds majority, a motion by any member
                      to reconsider no sooner than the next meeting is in order
                      and must be recorded in the minutes. The motion to
                      reconsider requires a simple majority for passage as does
                      the motion being reconsidered. If the vote is taken at the
                      next meeting or announced for a future time certain, no
                      further notice is required.
                    </p>
                    <p>
                      e. If consideration of a resolution taken up under VI.A.
                      5.c is postponed to a future meeting due to issues of
                      compliance with procedures or guidelines, the resolution
                      may be approved by majority vote at the meeting to which
                      it was postponed or at which it is taken from the table.
                    </p>
                    <p>
                      6. Special meetings of the Central Committee may be called
                      by the Chair, the First Vice-Chair (in the absence of the
                      Chair) or the Executive Committee as a body and shall be
                      announced to all members of the Central Committee by mail
                      or email sent as soon as possible, and in no case less
                      than six days prior to the special meeting.
                    </p>
                    <p>7. Electronic meetings:</p>
                    <p>
                      a. The Central Committee, Executive Committee, and other
                      committees and caucuses of the DPCC may choose to meet in
                      person, electronically, or in hybrid format (in-person
                      with an option for members to access the meeting
                      electronically) if equipment and personnel are available
                      to support the mode of operation in a way that “provides,
                      at a minimum, conditions of opportunity for simultaneous
                      aural communication among all participating members
                      equivalent to those of meetings held in one room or area.”
                      (<em>RONR</em>, 12th ed., 9:31) Normal notice and quorum
                      requirements will apply and voting is restricted to DPCC
                      PCPs attending the meeting in person or electronically.
                    </p>
                    <p>
                      b. Notification of the options available for attendance
                      must accompany notice of the meeting and be sent at least
                      six (6) days before the meeting. If electronic connection
                      is available, instructions for connection must be
                      circulated to all members by email no later than two (2)
                      days before the meeting.
                    </p>
                    <p>
                      8. Members shall provide up-to-date contact information to
                      the Secretary of the Central Committee, including mail,
                      phone, and email of record addresses. Members who cannot
                      be reached by email must so indicate on the contact form
                      and provide a mailing address and a phone number.
                    </p>
                    <p>
                      9. Notice of the cancellation of a meeting shall be made
                      as soon as possible by email to all members and posted on
                      the Central Committee website.
                    </p>
                    <p>
                      10. A quorum for Central Committee meetings shall be
                      forty-five members.
                    </p>
                    <p>
                      11. No proxies shall be permitted to participate in any
                      Central Committee meeting. (ORS 248.045) Voting shall be
                      limited to members of the Central Committee present at the
                      meeting.
                    </p>
                    <p>
                      B. Meetings of the Executive Committee shall be held once
                      a month or as needed.
                    </p>
                    <p>
                      1. Notification of meetings of the Executive Committee
                      shall be mailed or emailed to all members of the committee
                      no less than six days before a meeting.
                    </p>
                    <p>
                      2. The Chair prepares the agenda of meetings of the
                      Executive Committee. Requests to place items on the agenda
                      must be received by the Chair at least 10 days before a
                      meeting and may be referred to a standing or special
                      committee for consideration prior to being considered by
                      the Executive Committee.
                    </p>
                    <p>
                      3. Special meetings of the Executive Committee may be
                      called by the Chair, the Vice Chair or any four members of
                      the Executive Committee with six days prior notice sent to
                      all members of the Committee.
                    </p>
                    <p>
                      4. A quorum of the Executive Committee shall consist of
                      fifteen members.
                    </p>
                    <p>
                      5. No member of the Executive Committee may have more than
                      one vote.
                    </p>
                    <p>C. Organizational meeting of the Central Committee</p>
                    <p>1. Schedule and notice</p>
                    <p>
                      a. Not later than the Executive Committee meeting prior to
                      the primary election, the Executive Committee shall set
                      the date and time for the biennial organizational meeting,
                      which shall be held in November after general elections
                      (even-numbered years).
                    </p>
                    <p>
                      b. When the official results of the primary election are
                      available, the Chair shall obtain a list of elected PCPs
                      from the County Clerk’s office.
                    </p>
                    <p>
                      c. Not later than the 40<sup>th</sup> day before the
                      organizational meeting, the retiring county Central
                      Committee Chair shall file a written notice of the time,
                      date and place of the organizational meeting with the
                      county clerk and mail a copy of the notice to the State
                      Central Committee. (ORS 248.033-2)
                    </p>
                    <p>
                      d. The Chair of the retiring county Central Committee
                      shall send a mail or email copy of the notice to all
                      members of the Central Committee not later than 10 days
                      prior to the organizational meeting. (ORS 248.033-4)
                    </p>
                    <p>2. Procedure</p>
                    <p>
                      a. Officers of the retiring Central Committee shall make
                      available to the Committee at the beginning of the
                      organizational meeting all property, records, and funds
                      owned or controlled by the retiring
                    </p>
                    <p>Committee. (ORS 248.035-a)</p>
                    <p>
                      b. The following county officer positions shall be filled
                      at the organizational meeting: Chair, First ViceChair,
                      Second Vice-Chair, Secretary, Electronic Secretary and
                      Treasurer of the Central Committee. The First Vice-Chair
                      must be of a different gender from the Chair. Positions
                      shall be open to any registered Democrat in
                    </p>
                    <p>
                      Clackamas County with all newly elected PCPs eligible to
                      vote. (ORS 248.035.b)
                    </p>
                    <p>
                      c. A slate of nominees shall be presented by the
                      Nominations Sub-committee. However, any voting member of
                      the Central Committee may propose additional nominees for
                      any position, including self-nomination. Nominees shall
                      indicate willingness to serve by providing their name and
                      gender (male, female, or non-binary) on a signed form or
                      in oral communication with the Chair.
                    </p>
                    <p>
                      d. The Nominations Subcommittee shall select a temporary
                      chair who has previously served as an officer of the
                      Central Committee. The temporary chair shall preside over
                      the election of the Chair and First Vice-Chair of the
                      Central Committee. The newly elected Chair shall then
                      preside over the remainder of the meeting.
                    </p>
                    <p>
                      e. Only newly elected precinct committee persons whose
                      names are on the list of PCPs provided by the county clerk
                      may vote for officers at the organizational meeting.
                      However, at an election to fill a vacancy in a county
                      committee office, the list of current appointed and
                      elected PCPs obtained from the county clerk by the chair
                      shall determine eligibility to vote (ORS 248.026.4) and be
                      counted as part of a quorum (forty-five members) for the
                      meeting.
                    </p>
                    <p>
                      f. Incoming officers shall assume the duties of their new
                      offices as soon as practically possible after their
                      election, and no later than January 1 of the next year.
                    </p>
                    <p>g. The Rules Committee shall organize the election.</p>
                    <p>
                      h. In votes with more than one nominee, voters shall place
                      ballots in a ballot box. Ballots shall be counted and
                      totals reported to the Chair by tellers selected by the
                      Rules Committee. If only a single candidate is nominated
                      for an office, voice vote is permitted.
                    </p>
                    <p>
                      i. The newly elected Chair, within 48 hours of the Chair’s
                      election, shall send a list of the officers of the county
                      central committee to the county elections official and to
                      the DPO state central committee. This task may be
                      delegated to the Secretary.
                    </p>
                    <p>
                      j. Following the election of county officers, the
                      following positions shall be filled by election, with all
                      elected and appointed PCPs eligible to vote.
                    </p>
                    <p>
                      i. Delegates and alternates to the State Central
                      Committee. Members of the Central Committee shall elect
                      the delegates and alternates. The total number of
                      positions to be filled (delegates + alternates) shall be
                      listed on each ballot. All candidates shall be listed on
                      each ballot and identified as either male, female, or
                      non-binary. Names and genders of those nominated at the
                      meeting will be added to ballots by the voter. Central
                      Committee members must limit voting for any one gender to
                      no more than fifty percent of the total positions to be
                      filled (rounded up in the case of any partial number).
                      Non-binary delegate positions shall reduce the male and
                      female delegate positions as evenly as possible.
                      Replacement preference for alternate delegates will
                      utilize gender category first, number of votes received
                      second. The top vote getter willing to serve shall chair
                      the delegation. Subject to the conditions above higher
                      vote-getters shall be delegates; those with fewer votes
                      shall be alternates. Votes cast by each voter shall equal
                      the number of delegates to be selected.
                    </p>
                    <p>
                      ii. Delegates and alternates to congressional district
                      committees. Positions shall be filled by members of the
                      Central Committee elected using the same procedure as
                      described for delegates and alternates to the State
                      Central Committee.
                    </p>
                    <p>
                      iii. House District Leaders. HDLs shall be elected by
                      majority vote of PCPs in each house district following
                      election of delegates and alternates. For election of
                      house district leaders, a quorum shall consist of PCPs
                      representing at least one-third of the district’s
                      precincts in which PCPs currently serve. For house
                      districts that lack a quorum, the Chair shall appoint a
                      house district leader with the concurrence of the county
                      Central Committee at the next regular meeting.
                    </p>
                    <p>
                      3. Elected positions that are not filled at the
                      organizational meeting or that become vacant between
                      organizational meetings shall be filled by majority vote
                      at a regular Central Committee meeting or, in the case of
                      house district leaders, at the next house district
                      meeting.
                    </p>
                    <p>
                      4. After formal announcement of the date of the
                      organizational election, postponement of any part of the
                      election for reasons other than lack of candidates must be
                      approved by two-thirds majority vote of the Central
                      Committee at least one month before the newly-announced
                      date of the election.
                    </p>
                  </blockquote>
                  <p>
                    ARTICLE VII TENURE AND RESPONSIBILITIES OF PCPs, OFFICERS
                    AND DELEGATES
                  </p>
                  <blockquote>
                    <p>A. Precinct Committee Persons (PCPs)</p>
                    <p>
                      1. The tenure of elected precinct committee persons begins
                      on the 24<sup>th</sup>
                      day following their election and certification by the
                      county clerk and lasts until the end of the biennium or
                      their resignation or removal from office.
                    </p>
                    <p>
                      2. Qualified Democrats (ORS 248.015.4) may be appointed to
                      vacant PCP positions by vote of the Central Committee. The
                      tenure of an appointed PCP shall begin on the first
                      meeting following certification by the county clerk and,
                      unless the PCP resigns or is removed by the Central
                      Committee, continues until the certification of new PCPs
                      following the next primary election. (ORS 248.025.4)
                    </p>
                    <p>3. Precinct Committee Persons shall</p>
                    <p>
                      b. Represent the party at the precinct level and represent
                      their precinct voters at the Central
                    </p>
                    <p>Committee</p>
                    <p>
                      c. Work with other PCPs to rally support for the party
                    </p>
                    <p>
                      d. Participate in the meetings and activities of the
                      Central Committee and its standing and special committees
                    </p>
                    <p>
                      e. Participate as delegate or alternate to congressional
                      district committees and the State Central
                    </p>
                    <p>Committee</p>
                    <p>
                      f. Promote the interests of the party as identified in the
                      national, state and county platforms
                    </p>
                    <p>g. Work to elect Democratic Party nominees</p>
                    <p>
                      h. Provide the Secretary with a current mailing and email
                      address
                    </p>
                    <p>
                      i. Notify the Secretary of changes in mailing or email
                      address and of resignation from the position of
                    </p>
                    <p>PCP</p>
                    <p>
                      j. Notify the county elections office of any change in
                      residence and of intention to resign from the position of
                      PCP
                    </p>
                    <p>B. House District Leaders</p>
                    <p>
                      1. Up to two house district leaders shall be elected for
                      each house district, or portion thereof, in Clackamas
                      County by the PCPs of that house district. If a House
                      District does not have any elected House District Leaders,
                      they may choose a representative to attend Executive
                      Committee meetings, but that person shall not participate
                      in the meeting or have a vote.
                    </p>
                    <p>
                      Each person on the Executive Committee has one vote, no
                      matter how many offices they hold.
                    </p>
                    <p>
                      2. House district leaders shall serve as members of the
                      Executive Committee and may serve on other standing and
                      special committees.
                    </p>
                    <p>3. House district leaders shall</p>
                    <p>
                      a. Attend meetings of the Central Committee and Executive
                      Committee
                    </p>
                    <p>
                      b. Provide leadership and encouragement to the PCPs of
                      their district, including recruitment of volunteers and
                      PCPs
                    </p>
                    <p>
                      4. The tenure of house district leaders shall begin upon
                      formal announcement of their election by the DPCC Chair
                      and continue until the next reorganization election.
                    </p>
                    <p>
                      5. Vacancies in the position of house district leader
                      shall be filled by majority vote of the PCPs of the
                      district where the vacancy exists after notification of
                      all PCPs in that district by mail, email, or telephone six
                      or more days before the election.
                    </p>
                    <p>C. Duties of Officers</p>
                    <p>1. The Chair shall</p>
                    <p>
                      a. Preside at all meetings of the Central Committee and
                      the Executive Committee
                    </p>
                    <p>
                      b. Be the administrative officer of the County Central
                      Committee
                    </p>
                    <p>
                      c. Provide a written description of duties to the First
                      Vice-Chair and the Second Vice-Chair within one month of
                      their election
                    </p>
                    <p>
                      d. Provide a written agenda at all meetings and direct all
                      officers and committee chairs to report on activities
                      within their respective jurisdictions
                    </p>
                    <p>
                      e. Provide email notice of all Central Committee and
                      Executive Committee meetings or cancellation of meetings
                      to members at least six days prior to the meeting
                    </p>
                    <p>
                      f. Oversee all external correspondence activities and be
                      the chief spokesperson of the County Central
                    </p>
                    <p>Committee</p>
                    <p>g. Appoint all committee members</p>
                    <p>
                      h. With the advice and consent of the Executive Committee
                      appoint committee chairs and caucus chairs.
                    </p>
                    <p>i. Serve as delegate to the State Central Committee</p>
                    <p>
                      j. Serve as an ex-officio member of all standing
                      committees, caucuses and special committees
                    </p>
                    <p>
                      k. Perform other duties as assigned by the Central or
                      Executive Committee
                    </p>
                    <p>
                      l. Notify the county clerk in writing not later than the
                      40<sup>th</sup> day before the date of the county biennial
                      organizational meeting of the time, date, and place of the
                      meeting and provide a copy of the notice to the State
                      Central Committee. (ORS 248.033-2)
                    </p>
                    <p>
                      m. Obtain a list of elected PCPs from the county clerk to
                      serve as the official list of voters at the biennial
                      organizational meeting and provide each PCP on the list
                      with notice of the biennial organizational meeting by mail
                      or email at least ten days before the meeting (ORS
                      248.033-4). This task may be delegated to the Secretary.
                    </p>
                    <p>
                      n. Obtain from the county clerk just prior to any election
                      to fill a vacancy in a Central Committee office a list of
                      all appointed and elected PCPs and provide the list to the
                      Central Committee to establish the official list of
                      eligible voters. (ORS 248.035-3) This task may be
                      delegated to the Secretary.
                    </p>
                    <p>2. The First Vice-Chair shall</p>
                    <p>
                      a. Assume the necessary duties of the Chair in the
                      latter’s absence or incapacitation and succeed to the
                      office of Chair if a vacancy occurs
                    </p>
                    <p>b. Serve as delegate to the State Central Committee</p>
                    <p>
                      c. Assist with the oversight and management of committees
                      as assigned by the Chair
                    </p>
                    <p>d. Perform other duties as assigned by the Chair</p>
                    <p>
                      e. Succeed to the position of Chair if that position
                      becomes vacant
                    </p>
                    <p>3. The Second Vice-Chair shall</p>
                    <p>
                      a. Assist with the oversight and management of committees
                      as assigned by the Chair
                    </p>
                    <p>b. Perform other duties as assigned by the Chair</p>
                    <p>
                      c. Succeed to the position of First Vice-Chair if that
                      position becomes vacant, and be replaced by majority vote
                      election of a new Second Vice-Chair at the next Central
                      Committee meeting, in accordance with Art. VII.D.2 of
                      these bylaws.
                    </p>
                    <p>4. The Recording Secretary shall</p>
                    <p>
                      a. Record and maintain the minutes of all Central and
                      Executive Committee meetings
                    </p>
                    <p>
                      b. Be the custodian of all county Central Committee
                      written records and provide true copies as needed
                    </p>
                    <p>
                      c. Assist with preparation of meeting materials such as
                      ballots, tally sheets, etc.
                    </p>
                    <p>
                      d. Record and post text of motions, etc. during electronic
                      meetings
                    </p>
                    <p>
                      e. In cooperation with County Clerk’s office, maintain a
                      current record of all elected and appointed
                    </p>
                    <p>PCPs</p>
                    <p>
                      f. In cooperation with Electronic Meeting Secretary,
                      maintain attendance records of all Central Committee
                      meetings
                    </p>
                    <p>5. The Electronic Communications Secretary shall</p>
                    <p>
                      a. Assist in managing electronic functions during
                      electronic meetings of the Executive Committee and
                    </p>
                    <p>Central Committee</p>
                    <p>
                      b. In cooperation with the Recording Secretary maintain a
                      current list of all members with email and telephone
                      contact information.
                    </p>
                    <p>
                      c. Assist the chair in communicating with the membership,
                      including distributing meeting announcements and notices
                      as needed
                    </p>
                    <p>
                      d. Oversee the attendance list at electronic meetings,
                      including voice check-in of meeting attendees and provide
                      necessary information regarding the participants list.
                    </p>
                    <p>6. The Treasurer shall</p>
                    <p>
                      a. Be the custodian of all funds of the county Central
                      Committee
                    </p>
                    <p>b. Maintain financial records</p>
                    <p>
                      c. Each month provide to the Executive Committee and
                      Central Committee written status reports on the financial
                      position of the organization, forecasting any
                      financial/budgetary issues requiring the attention of the
                      organization and recommending actions to address any
                      financial/budgetary issues identified
                    </p>
                    <p>
                      d. Upon request provide detail for contributions and
                      expenditures to the Executive and/or Central
                    </p>
                    <p>Committees</p>
                    <p>
                      e. Complete and file accurate and timely reports of
                      contributions and expenditures required by Oregon
                    </p>
                    <p>Revised Statutes</p>
                    <p>
                      f. Establish and maintain a reserve Fund to equal six
                      months of operating expense (based on the current annual
                      budget)
                    </p>
                    <p>
                      g. Along with the Second Vice-Chair, oversee facility
                      leases and related contracts
                    </p>
                    <p>
                      h. The treasurer shall have the ability and experience of
                      creating spreadsheets and the use of accounting software.
                    </p>
                    <p>D. Resignation of officers of the Central Committee</p>
                    <p>
                      1. No person who files for election to a county, Metro,
                      legislative or state office shall be eligible to hold the
                      office of Chair, First Vice-Chair, Second Vice-Chair,
                      Secretary or Treasurer of the Democratic Party of
                      Clackamas County Central Committee. The position of any of
                      the aforementioned officers who files for a county, Metro,
                      legislative or state office shall be declared vacant.
                    </p>
                    <p>
                      2. Officer vacancies shall be filled by majority vote at
                      any regular meeting of the Central Committee. An
                      announcement of the vacancy shall be sent by mail or email
                      six or more days before any regular meeting and may be
                      posted to the website prior to the regular meeting.
                      Nominations and election shall be made at the meeting.
                      Only elected and appointed PCPs shall be eligible to vote.
                    </p>
                    <p>E. Standing Committee Chairs</p>
                    <p>
                      1. Standing committee chairs and co-chairs are appointed
                      by the Central Committee Chair with the advice and consent
                      of the Executive Committee and may be removed by the Chair
                      with the consent of the Executive Committee.
                    </p>
                    <p>
                      2. In addition to serving as members of the Executive
                      Committee, standing committee chairs shall convene their
                      committees and organize their work under the direction of
                      the Central Committee Chair and the Executive and Central
                      Committees. They shall cooperate with other committees in
                      areas of overlapping responsibility and shall report to
                      the Executive and Central Committees as needed.
                    </p>
                    <p>F. Caucus Chairs</p>
                    <p>
                      1. Caucus chairs are appointed by the Chair of the Central
                      Committee with the advice and consent of the Executive
                      Committee and may be removed by the Chair with the consent
                      of the Executive Committee. After approval of the caucus
                      bylaws by the Central Committee, the chair and other
                      officers will be elected and retained as specified
                      therein.
                    </p>
                    <p>
                      2. Caucus chairs shall serve as members of the Executive
                      Committee. Each caucus may have one representative on the
                      Executive Committee.
                    </p>
                    <p>G. Delegates and alternates</p>
                    <p>
                      1. Members of the Democratic Party of Clackamas County who
                      are elected or appointed as delegates and/or alternates to
                      the State Central Committee or to a congressional district
                      committee are required to attend the meetings of those
                      organizations and report back to the county Central
                      Committee.
                    </p>
                    <p>
                      2. Any delegate who has two consecutive avoidable absences
                      shall be subject to removal, and the vacancy shall be
                      filled by the appointing authority.
                    </p>
                    <p>
                      3. The delegation chair shall oversee the attendance and
                      credentialing of delegates and alternates and ensure that
                      alternates are assigned voting delegate status in place of
                      absent delegates. Alternates shall replace absent
                      delegates according to (a) gender (to the extent possible)
                      and (b) votes received at the time of election. If vacant
                      voting slots remain after all delegates and alternates
                      present have been
                    </p>
                    <p>
                      assigned voting status, any PCP from Clackamas County
                      present may be assigned voting status, keeping gender
                      balance in the delegation to the extent possible.
                    </p>
                    <p>
                      4. The county Chair, or the county First Vice-Chair if the
                      Chair is not available, in coordination with the
                      delegation chair, will appoint and approve temporary
                      delegates. Any issues relating to credentialing of
                      delegates will be referred to the county Chair for
                      decision.
                    </p>
                  </blockquote>
                  <p>
                    ARTICLE VIII COMMITTEES, CAUCUSES AND THEIR RESPONSIBILITIES
                  </p>
                  <blockquote>
                    <p>A. Executive Committee</p>
                    <p>
                      1. Officers of the county Central Committee, house
                      district leaders, chairs of the standing committees,
                      chairs to both the SCC and Congressional districts, and
                      chairs of caucuses shall be known as the Executive
                      Committee.
                    </p>
                    <p>
                      2. The Executive Committee shall be the administrative
                      unit of the Central Committee and shall have the following
                      duties:
                    </p>
                    <p>
                      a. Suggest policy and programs to the Central Committee
                      and assist in implementation of Central
                    </p>
                    <p>Committee programs and activities</p>
                    <p>b. Coordinate the work of the committees</p>
                    <p>
                      c. Allocate budgeted campaign funds to candidates with
                      notification to the Central Committee
                    </p>
                    <p>
                      d. Hire office personnel as authorized and budgeted by the
                      Central Committee
                    </p>
                    <p>
                      e. Perform other duties as may be authorized by these
                      Bylaws or assigned by the Central Committee
                    </p>
                    <p>
                      f. Authorize non-budget expenditures limited to $200
                      without approval of the Central Committee. Such
                      expenditures must be reported to the Central Committee at
                      the earliest opportunity.
                    </p>
                    <p>
                      g. Authorize use of Reserve Funds in emergency
                      circumstances without prior approval of the Central
                      Committee for budgeted expenditures or for non-budgeted
                      expenditures not exceeding $500. Use of the Reserve Fund
                      for any reason must be approved by the Central Committee
                      or by a majority of the membership of the Executive
                      Committee with notification to the Central Committee at
                      the earliest opportunity.
                    </p>
                    <p>B. Standing Committees</p>
                    <p>
                      1. The Events and Facilities Committee shall oversee
                      staffing, facilities, and other arrangements necessary for
                      the party office, regular and special meetings of the
                      Executive Committee and Central Committee, and special
                      activities such as operating booths at the state and
                      county fairs. Working with the Treasurer and the Second
                      Vice-Chair, the committee shall investigate and recommend
                      appropriate lease arrangements for the county office.
                    </p>
                    <p>
                      2. The Campaign Committee shall coordinate efforts to
                      identify, recruit, vet, train and support potential and
                      existing candidates throughout the county. The overall
                      purpose of the committee is to recruit Democrats to fill
                      both partisan and non-partisan elective and appointive
                      offices at every level of the county. Committee membership
                      shall reflect a diverse demographic of racial, gender,
                      ethnic, age, sexual orientation and county location.
                    </p>
                    <p>
                      3. The Communications Committee, under the supervision of
                      the DPCC Chair and First Vice Chair or Second Vice-Chair,
                      shall manage and maintain the internal and external
                      communications and public relations of the DPCC, including
                      messaging and communications with news media and affinity
                      groups; an online newsletter, website, Facebook site; and
                      other electronic outreach sites. The committee chair shall
                      be assisted by members with appropriate technical and
                      messaging skills.
                    </p>
                    <p>
                      4. The Finance-Fundraising Committee shall oversee the
                      finances and fundraising activities of the Central
                      Committee. The committee shall study the financial needs
                      of the DPCC and oversee preparation of the annual budget.
                      Membership of the committee, in addition to the Chair,
                      shall include the Treasurer, Chair and First Vice Chair or
                      Second Vice-Chair, of the DPCC, and at least one member
                      who is not a member of the Executive Committee. The
                      Treasurer shall be primarily responsible for overseeing
                      the preparation and management of the DPCC budget.
                    </p>
                    <p>
                      5. The Neighborhood Leader Program Committee shall oversee
                      the organization and management of the Neighborhood Leader
                      Program (NLP). The purpose of the committee is to recruit
                      Neighborhood Leaders to encourage county Democrats to “Get
                      Out the Vote” (GOTV), focusing primarily on encouraging
                      Democrats in the county to vote during primary, special,
                      and general elections. The Chair shall be assisted by two
                      NLP co-coordinators, one responsible for recruiting and
                      training NL’s and one responsible for overseeing data
                      management, in addition to committee members who will
                      assist with cutting turf and data analysis.
                    </p>
                    <p>
                      6. The Platform and Resolutions Committee shall consider
                      proposals for submission to the DPO Platform/Resolutions
                      Committee in preparation for the biennial DPO platform
                      convention and shall facilitate Clackamas County
                      participation in that convention. It shall also consider
                      and facilitate discussion of issues and positions to be
                      taken by the Central Committee on ad hoc issues and work
                      with the Social Justice Committee to facilitate the
                      development of resolutions emanating from discussions and
                      issues raised by that committee. The committee shall
                      periodically provide information about legislation related
                      to legislative action items in the DPO Platform. In
                      addition to the chair, the committee shall include a
                      member from the Executive Committee, a member from the
                      Social Justice Committee, and members familiar with
                      various areas of concern.
                    </p>
                    <p>
                      7. The Rules Committee shall provide guidance on matters
                      relating to the Bylaws, Standing Rules, and parliamentary
                      procedure. The Committee shall supervise the conduct of
                      elections, including the election of a Nominations
                      Sub-Committee at least six weeks prior to a Central
                      Committee election meeting. The Nominations Sub-Committee
                      shall provide a list of all nominees for Central Committee
                      offices at the biennial organizational election and
                      recommend at least one qualified candidate for any interim
                      vacancy among elected officers of the DPCC. The committee
                      chair shall serve as the chief parliamentarian for the
                      organization and be assisted by additional members
                      familiar with parliamentary procedure.
                    </p>
                    <p>
                      8. The Justice Committee shall focus on four justice
                      areas: economic justice, environmental justice, racial
                      justice, and social justice. The committee shall
                      investigate issues of importance to Democrats, including
                      analysis of different perspectives. The committee may make
                      its findings available to the Program Committee for
                      potential presentations, to the Communications Committee
                      in written form for potential articles on the website and
                      other electronic outreach venues, and to the Platform and
                      Resolutions Committee for the development of possible
                      resolutions for consideration by the Central Committee.
                      The Committee may also report to the Executive Committee
                      and the Central Committee regarding opportunities for
                      action related to justice issues. Membership of the
                      Committee will include, in addition to the committee chair
                      and DPCC Chair, a DPCC Vice Chair and members with
                      analysis and writing skills who represent diverse
                      viewpoints.
                    </p>
                    <p>
                      9. The Labor Committee shall focus on the rights and
                      concerns of workers. The purpose of the committee is to 1)
                      address issues and concerns relating to the rights and
                      well-being of workers in Clackamas County, including the
                      right of all workers to a family wage and to safe,
                      reasonable conditions of work, as well as to join unions
                      and to engage in collective bargaining; 2) establish
                      lasting and meaningful connections with organized labor
                      affiliates and members that live and work in Clackamas
                      County; 3) assist in articulating the needs and concerns
                      of laborers in policy positions taken by the Democratic
                      Party of Clackamas County and the Democratic Party of
                      Oregon; and 4) facilitate awareness at all levels of the
                      importance of workers and organized labor in the economic
                      well-being of our society. The Committee shall be
                      comprised of a Chair; a vice-chair who shall reach out to
                      labor unions, support the efforts of the Chair, and
                      substitute for the Chair as needed; and at least five
                      members selected by the county chair in consultation with
                      the committee co-chairs.
                    </p>
                    <p>
                      10. The Technical Operations Committee shall provide
                      information and assistance to the DPCC Chair, Officers and
                      Executive Committee members in the selection and use of
                      electronic communications devices, including means of
                      conducting electronic meetings, the use of communications
                      systems such as the DPO Votebuilder (VAN) system, and to
                      assist in troubleshooting hardware and software issues.
                      The
                    </p>
                    <p>
                      Technical Operations Chair will work closely with the
                      Communications Committee, the Neighborhood
                    </p>
                    <p>
                      Leader Committee and other committees and caucuses as
                      needed. Responsibilities will include administration and
                      support of digital file storage and workflow systems
                      including website, email and data backup platforms,
                      protocols and process; and management of internal VAN
                      accounts, related software systems, communication with DPO
                      staff on technical issues relating to VAN. Members of the
                      committee will be selected from those with the technical
                      background necessary to handle the responsibilities of the
                      committee. A primary role of Technical Operations
                      Committee members is to remain up to date* on
                      technological systems and hardware and to provide
                      Executive Committee members and local campaigns training
                      in use of programs including VAN and Zoom or other
                      approved electronic meeting and technical support programs
                    </p>
                    <p>
                      11. The PCP Recruitment and Volunteer Placement Committee
                      shall assist in recruitment and intake of new PCPs and new
                      volunteers, processing inquiries from the DPCC website and
                      elsewhere, helping prospective PCPs and volunteers
                      complete the application process, and informing them about
                      opportunities to participate in the county party,
                      including as members of house districts and committees.
                      The committee will help establish contact between
                      prospective PCP applicants and HDLs from their house
                      district so they can complete the application process. In
                      addition, the PCP Recruitment and Volunteer Placement
                      Committee will maintain contact with leaders of DPCC
                      Chair, officers, house districts, caucuses, committees and
                      assist them in finding volunteers to be involved in party
                      events and activities. To facilitate this process, the
                      committee will maintain a file of the skills and interests
                      of individual PCPs and volunteers, along with their
                      contact information and record of service in the party.
                      This file will be available to those organizing DPCC
                      events and activities. In addition to the committee Chair
                      and a DPCC officer, the committee will include members
                      from different parts of the county.
                    </p>
                    <p>C. Caucuses</p>
                    <p>
                      1. Caucuses consist of groups of Clackamas County
                      Democrats who serve as advocacy groups for common
                      interests, views, or identities in the Democratic Party of
                      Clackamas County, the Democratic Party of Oregon, and the
                      broader community, including support for Democratic
                      candidates in partisan races.
                    </p>
                    <p>
                      2. Current standing committees that may fit the definition
                      of a caucus (Education, Justice, Labor, Young Democrats)
                      may be automatically designated as a caucus upon their
                      request and approval of the Executive Committee and the
                      Central Committee.
                    </p>
                    <p>
                      3. New caucuses shall be formed by completing the
                      following process:
                    </p>
                    <p>
                      a. Submission by a Clackamas County Democrat, in
                      consultation with the county Chair, of a proposal to the
                      Executive Committee describing the mission of the caucus
                      accompanied by signatures endorsing the proposal of at
                      least five Clackamas County Democrats from at least three
                      house districts.
                    </p>
                    <p>
                      b. With approval by the Executive Committee, the caucus
                      organizer may begin recruitment of members and initiate
                      meetings of the caucus which will include producing a
                      proposed set of bylaws in consultation with the Rules
                      Committee.
                    </p>
                    <p>
                      c. The proposed bylaws and a list of members must be
                      submitted to the Executive Committee to initiate the
                      process of approval of the caucus by the Central
                      Committee.
                    </p>
                    <p>
                      d. Upon approval of the caucus by the Central Committee,
                      the chair of the caucus will become a member of the
                      Executive Committee.
                    </p>
                    <p>
                      4. Caucuses must have at least five active members and
                      must meet at least twice a year. Each June, caucuses must
                      provide the county chair with a list of members who have
                      attended at least two meetings or other activities of the
                      caucus and a list of meetings and activities conducted
                      during the previous calendar year.
                    </p>
                    <p>
                      5. Groups listed as of January, 2020 as standing
                      committees in the DPCC wishing to become caucuses shall be
                      recognized as caucuses upon request of the committee, with
                      existing chairs continuing in office. In subsequent years,
                      they will follow normal procedures for caucuses.
                    </p>
                    <p>
                      6. Caucuses shall maintain a membership list consisting of
                      those who attend two or more meetings or activities of the
                      caucus per year. Membership with voting rights in the
                      caucus is open to all Democrats registered to vote in
                      Clackamas County.
                    </p>
                    <p>
                      7. Caucuses that fail to submit reports and/or fail to
                      meet at least twice in a calendar year for two years shall
                      be declared inactive. They will not have representation on
                      the Executive Committee until they provide a record of at
                      least two meetings attended by five or more members and
                      otherwise comply with the requirements set forth in the
                      DPCC bylaws. Caucuses that become inactive over a period
                      of four years may be removed from the list of caucuses by
                      vote of the Central Committee.
                    </p>
                    <p>8. Caucuses:</p>
                    <p>
                      a. The Young Democrats Caucus shall seek to attract to the
                      DPCC young people (focusing on those under the age of 36)
                      from diverse backgrounds who are committed to Democratic
                      values. In addition to involving youth in Party programs
                      and activities, the Young Democrats will provide
                      encouragement and support to young Democrats willing to
                      run for political office and to seek appointments to local
                      and district committees and boards. In addition to the
                      committee chair and DPCC Chair, membership shall include
                      the First Vice Chair or Second Vice-Chair, a
                      representative of the Campaign Committee, young people,
                      and others committed to helping increase youth involvement
                      in the Democratic Party.
                    </p>
                    <p>
                      b. The Education Caucus shall focus on public education
                      from pre-kindergarten through grade 12; post-secondary
                      schools; and vocational/technical training. By
                      facilitating outreach to stakeholders in the educational
                      community interested in advocating and promoting public
                      education issues, the Committee will help build
                      understanding of challenges currently faced by public
                      education at all levels, as well as innovative and best
                      practices current in the field of education. This will
                      assist the Central Committee in contributing
                      constructively to supporting improvements in education in
                      accordance with Democratic Party principles such as equal
                      opportunity for all. The Committee will be comprised of a
                      Chair, at least five members with interest in educational
                      issues, and the DPCC Chair or designee.
                    </p>
                    <p>
                      c. The Black Caucus works to facilitate full participation
                      of Black/African American people in Clackamas County
                      political processes at all levels and to increase the
                      responsiveness of government to the views and needs of
                      Black/African American people through:
                    </p>
                    <p>
                      A. Increasing input and participation at all levels in the
                      Clackamas County Democratic party by Black/African
                      American residents
                    </p>
                    <p>
                      B. Providing outreach, training and support to increase
                      participation by Clackamas County Black/African American
                      Democrats in community leadership activities, including
                      engaging in public service and pursuing political office;
                    </p>
                    <p>
                      C. Serving as a forum to explore issues through dialogue
                      and educate Democrats, officials and the general public on
                      legislation and policies that will help to eliminate
                      structural racism in Oregon communities.
                    </p>
                    <p>D. Special Committees</p>
                    <p>
                      1. Special Committees may be formed by the Chair, with the
                      advice and consent of the Executive Committee, for any
                      purpose that will advance the functions of the national,
                      state or county Party, the Central Committee or the
                      Executive Committee.
                    </p>
                    <p>
                      2. The Chair shall choose a chair and members of special
                      committees appropriate to the responsibilities of the
                      committee.
                    </p>
                    <p>
                      3. Special Committees shall be disbanded once they have
                      performed their functions.
                    </p>
                  </blockquote>
                  <p>ARTICLE IX RECALL AND REMOVAL OF PCPS AND OFFICERS</p>
                  <blockquote>
                    <p>
                      A. An elected PCP may be recalled only by a petition
                      signed by not less than 25% of the number of party members
                      who voted in the precinct as it was constituted at the
                      preceding primary election. The petition must state the
                      reasons for the recall in 200 words or less. The PCP may
                      resign before the fifth day after the petition is filed
                      with the county clerk. If the PCP does not resign, a
                      recall election is held within twenty-five days after the
                      petition was filed at the cost of the county central
                      committee. The ballot shall contain the 200-word
                      explanation filed in support of the recall and a 200-word
                      response by the PCP being recalled, with the recall being
                      sustained if a majority of voters support the petition.
                      The PCP shall continue to perform duties until the results
                      of the election are announced.
                    </p>
                    <p>
                      B. Appointed PCPs serve at the pleasure of the Central
                      Committee and may be removed for nonperformance of duties
                      by a two-thirds vote of the Central Committee.
                    </p>
                    <p>
                      C. House district leaders may be removed for failure to
                      perform their duties upon petition to the Executive
                    </p>
                    <p>
                      Committee of 25% of the PCPs in the house district or,
                      pursuant to a motion for removal by the county Chair. In
                      either case, the motion for removal shall state the
                      grounds for removal, and the house district leader shall
                      have the opportunity to resign without contesting the
                      charges or to present a defense to the adjudicating body
                      prior to a removal vote. Removal requires a two-thirds
                      majority of votes cast.
                    </p>
                    <p>
                      D. Every elected officer of the County Central Committee
                      is subject, as herein provided, to recall by the precinct
                      committee persons of the Clackamas County Democratic
                      Central Committee.
                    </p>
                    <p>
                      E. If an officer (but not a committee, delegation or
                      caucus chair or House District Leader) has three
                      consecutive unexcused absences from Central Committee
                      meetings, the Executive Committee shall declare the office
                      vacant. Absences may be excused in advance or, in the case
                      of emergency, after the fact, by the Chair or First
                      Vice-Chair.
                    </p>
                    <p>
                      F. The Central Committee shall consider the recall of an
                      elected officer if 25% of the PCPs petition for such
                      recall. The officer who is the subject of the petition may
                      present his/her case prior to balloting, and shall be
                      given the option of resigning the office prior to any such
                      recall balloting. An affirmative two-thirds vote of the
                      members present and voting is required for recall. All
                      such actions shall be conducted in closed session.
                    </p>
                    <p>
                      G. Should the Executive Committee conclude that any
                      elected officer is negligent of his/her duties or unable
                      to perform them, it shall give such elected officer the
                      option of resigning the office rather than face a recall
                      decision. If a resignation is tendered, it shall be
                      accepted and take effect upon the day it is offered, and
                      the vacancy filled as provided in these Bylaws. If a
                      resignation is not tendered, the Executive Committee shall
                      publish its conclusions <del>thirty</del> six days prior
                      to any Central Committee meeting and call for a vote of
                      recall at that time. The officer may present his/her case
                      before a vote is taken. An affirmative two-thirds majority
                      vote of the members present and voting is required for
                      recall. All such actions shall be conducted in a closed
                      meeting.
                    </p>
                  </blockquote>
                  <p>ARTICLE X PROHIBITIONS</p>
                  <blockquote>
                    <p>
                      A. The following acts now and forever shall be prohibited:
                    </p>
                    <p>
                      1. Use of the name of this organization in connection with
                      any office, group or campaign where the county Central
                      Committee, the Executive Committee or their designated
                      representative has not given its permission.
                    </p>
                    <p>
                      2. Authorizing the use of this organization’s name or any
                      of its property or permits in violation of these Bylaws.
                    </p>
                    <p>
                      B. No member may speak or write on behalf of the DPCC
                      without the explicit permission of the Central Committee
                      Chair.
                    </p>
                  </blockquote>
                  <p>ARTICLE XI AMENDMENTS</p>
                  <blockquote>
                    <p>
                      A. These Bylaws shall be subject to amendment at any
                      meeting of the Central Committee provided that
                    </p>
                    <p>
                      1. The proposed amendment has been reviewed and given a
                      positive recommendation by the Rules committee and the
                      Executive Committee
                    </p>
                    <p>
                      2. A copy of the proposed amendment, together with the
                      portion of the Bylaws it would change and the
                      recommendation for its disposition by the Rules Committee
                      has been submitted by the Executive Committee and sent by
                      mail or email to the members of the Central Committee six
                      or more days before any regular meeting
                    </p>
                  </blockquote>
                  <p>
                    B. If an amendment is presented more than 30 days after
                    publication at a regular meeting of the Central Committee a
                    two-thirds affirmative vote of those present and voting
                    shall be required for approval. ARTICLE XII STANDING RULES
                  </p>
                  <blockquote>
                    <p>
                      A. These Bylaws shall be supplemented by a set of Standing
                      Rules dealing with details of implementation and
                      administration.
                    </p>
                    <p>
                      B. The Standing Rules shall be subsidiary to these Bylaws
                      and shall be subject to change by the Central Committee
                      following a motion, a second and an affirmative majority
                      vote by those PCPs present and voting.
                    </p>
                  </blockquote>
                  <p>ARTICLE XIII SEVERANCE CLAUSE</p>
                  <blockquote>
                    <p>
                      A. If any portion of these Bylaws or the Standing Rules
                      propagated by authority hereof is found to be unlawful
                      only that portion of the Bylaws or Standing Rules shall be
                      null and void.
                    </p>
                    <p>
                      B. Such nullification shall be without effect upon the
                      remaining portion of this document.
                    </p>
                    <p>
                      [ADOPTED: November 18, 2021: (1) Revision to Article VIII
                      to remove VIII.B.7 (Program Standing Committee) from the
                      list of Standing Committees and re-number remaining
                      Standing Committees. (2) Revision to add "11. PCP
                      Recruitment and Volunteer Placement Committee" to Article
                      VIII.B. 59 PCPs were in attendance; there were no
                      objections to or abstentions from either motion; both were
                      adopted]
                    </p>
                    <p>
                      [ADOPTED April 21, 2023- Grammatical corrections, addition
                      of the Electronic Secretary position, addition of
                      Congressional District 6, clarification of voting
                      requirements for HDL elections, addition of the Black
                      Caucus, deletion of the Latinx Caucus, addition of
                      delegation chairs to the Executive Committee,
                      clarification of voting membership of the Executive
                      Committee and minor clarifications throughout. There were
                      no objections to any changes.]
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
}

export default DpccByLaw;
