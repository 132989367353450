import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/Delegates/Content";
import Breadcrumbs from "../layouts/Breadcrumbs";
import { useLocation } from "react-router-dom";

function Delegates() {
  const location = useLocation();

  const pageTitle = "Delegates | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Democratic Party of Clackamas County delegates. Find information about state central committee delegates and delegates to local congressional districts."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Delegates" }} />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default Delegates;
