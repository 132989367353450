import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Breadcrumbs from "../layouts/Breadcrumbs";

import NeighborhoodLeadersImage from "../../assets/img/banner/neighborhood-leader-graphic.jpeg";
import { useLocation } from "react-router-dom";

function NeighborhoodLeaders() {
  const location = useLocation();

  const pageTitle = "Neighborhood Leader Program | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Learn about the Democratic Party of Clackamas County Neighborhood Leader Program. Find out what we do and then sign up to become a neighborhood leader today."
        />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: "Neighborhood Leaders Program" }} />
      <section className="client-section">
        <div className="container">
          <div className="row p-md-5 p-sm-0" style={{ padding: "14px 0" }}>
            <div className="col pt-3">
              <div className="card">
                <div className="card-body p-3">
                  <p>If you’d like to get involved, please get in touch! </p>
                  <a
                    href="https://www.clackamasdems.org/#/contacts-and-organization"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.clackamasdems.org/#/contacts-and-organization
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-md-5 p-sm-0">
            <div className="col pt-3">
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "24px",
                }}
              >
                <div className="card" style={{ marginBottom: "36px" }}>
                  <div className="card-body">
                    <p>
                      Current neighborhood leaders,{" "}
                      <a href="https://oregonnlp.org/">sign into Oregon NLP</a>
                    </p>
                  </div>
                </div>
                <img
                  src={NeighborhoodLeadersImage}
                  alt=""
                  style={{
                    width: window.innerWidth < 875 ? "100%" : "50%",
                  }}
                />
              </div>

              <div className="card">
                <div className="card-header">
                  <h3>Description</h3>
                </div>
                <div className="card-body">
                  <p>
                    The Neighborhood Leader Program Committee shall oversee the
                    organization and management of the Neighborhood Leader
                    Program (NLP). The purpose of the committee is to recruit
                    Neighborhood Leaders to encourage county Democrats to “Get
                    Out the Vote” (GOTV), focusing primarily on encouraging
                    Democrats in the county to vote during primary, special, and
                    general elections. The chair shall be assisted by two NLP
                    co-coordinators, one responsible for recruiting and training
                    NL’s and one responsible for overseeing data management, in
                    addition to committee members who will assist with cutting
                    turf and data analysis.
                  </p>
                  <br></br>
                  <p>
                    We know that direct contact from neighbors is the most
                    effective way to turn out our progressive Democratic voters.
                    It’s also the best way to communicate our values and cut
                    through the media echo machine. Neighborhood Leaders (NLs)
                    support the core of our mission. NLs help campaigns that
                    embody Democratic values win elections by contacting 35 or
                    so of their Democratic neighbors each election cycle. By
                    providing the 'slate' of candidates to Democrats, they
                    nourish relationships & support the habit of voting. Won’t
                    you join us and help promote our Democratic values and
                    candidates in YOUR neighborhood?
                  </p>
                </div>
                <div className="card-header">
                  <h3>Join</h3>
                </div>
                <div className="card-body">
                  <p>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSee3MNFm-Q7DA4jzBEXm47weJnBM8O0Q4ay9J9DBozKL_4vig/viewform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click here
                    </a>{" "}
                    to ask about joining the Neighborhood Leaders Program.
                  </p>
                </div>
                <div className="card-header">
                  <h3>Training</h3>
                </div>
                <div className="card-body">
                  <p>
                    <a href="/nl-training">Get training</a> to become a
                    neighborhood leader.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default NeighborhoodLeaders;
